<template>
  <div>
    <base-module :moduleOptions="options" title="Empresas" ref="module">
      <template v-slot:documentsformfields>
        <b-form-group label="Nombre" class="flex-1">
          <b-form-input name="name"> </b-form-input>
        </b-form-group>
        <b-form-group label="Archivo" class="flex-1">
          <b-form-file
            name="file"
            ref="file"
            accept=".jpg, .png, .gif, .pdf"
          ></b-form-file>
        </b-form-group>
      </template>
      <template v-slot:recorddetails="slotProps">
        <div class="flex flex-col space-y-2">
          <h3>{{ slotProps.name }}</h3>
          <div>
            <strong>NIT.</strong>
            {{ slotProps.nit | numericFormat }}
          </div>
          <div class="flex space-x-4">
            <div class="flex-1">
              <span class="font-semibold">email:</span> {{ slotProps.email }}
            </div>
            <div class="flex-1">
              <span class="font-semibold">Teléfono:</span> {{ slotProps.phone }}
            </div>
          </div>
          <div>
            <span class="font-semibold">Representante legal:</span>
            {{ slotProps.legal_representative }}
          </div>
          <div class="flex space-x-4">
            <div class="flex-1">
              <span class="font-semibold">Dirección:</span>
              {{ slotProps.address }}
            </div>
            <div v-if="slotProps.city !== undefined" class="flex-1">
              <span class="font-semibold">Ciudad:</span>
              {{ slotProps.city.name }}
            </div>
          </div>
        </div>
      </template>
    </base-module>
    <enterprise-form
      :handlePostSubmit="handlePostSubmit"
      :record="selectedRow"
    />
  </div>
</template>

<script>
import { customComponentActions } from "@/mixins/actions.js";
import EnterpriseForm from "./EnterpriseForm";
import BaseModule from "@/components/Base/BaseModule";
export default {
  inject: ["enterprisesRepository"],

  mixins: [customComponentActions],

  components: {
    BaseModule,
    EnterpriseForm
  },

  data() {
    return {
      options: {
        columns: [
          {
            header: "NIT",
            prop: "nit"
          },
          {
            header: "Nombre",
            prop: "name"
          },
          {
            header: "Dirección",
            prop: "address"
          },
          {
            header: "Teléfono",
            prop: "phone"
          },
          {
            header: "e-mail",
            prop: "email"
          },
          {
            header: "Ciudad",
            prop: "city.name"
          }
        ],
        repository: {
          name: "enterprisesRepository"
        },
        primary: "id",
        sortable: ["nit", "name"]
      },
      selectedRow: null
    };
  },

  methods: {
    handlePostSubmit() {
      this.$refs.module.handleLoadData();
    }
  }
};
</script>

<style lang="scss" scoped></style>
